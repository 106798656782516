function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(obj, key, value) {key = _toPropertyKey(key);if (key in obj) {Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });} else {obj[key] = value;}return obj;}function _toPropertyKey(t) {var i = _toPrimitive(t, "string");return "symbol" == typeof i ? i : i + "";}function _toPrimitive(t, r) {if ("object" != typeof t || !t) return t;var e = t[Symbol.toPrimitive];if (void 0 !== e) {var i = e.call(t, r || "default");if ("object" != typeof i) return i;throw new TypeError("@@toPrimitive must return a primitive value.");}return ("string" === r ? String : Number)(t);}import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setSelection, unsetSelection } from "../actions/selectionActions";
import {
  resetReferenceCreation,
  highlightReference,
  activateReference,
  scrollToActive,
  deactivateReference } from "../actions/uiActions";

import Document from "./Document";
import { createSelector } from 'reselect';
import { selectDoc, selectReferences } from "../selectors";

const selectSourceRange = createSelector(
  (s) => s.uiState,
  (u) => u.toJS().reference.sourceRange
);
const selectActiveRef = createSelector(
  (s) => s.uiState,
  (u) => u.toJS().activeReference
);

const mapStateToProps = (state) => {
  const { user, documentViewer } = state;
  return {
    selectedSnippet: documentViewer.uiState.get('snippet'),
    selection: selectSourceRange(documentViewer),
    doScrollToActive: documentViewer.uiState.get('goToActive'),
    doc: selectDoc(state),
    references: selectReferences(state),
    className: 'sourceDocument',
    activeReference: selectActiveRef(documentViewer),
    executeOnClickHandler: !!documentViewer.targetDoc.get('_id'),
    disableTextSelection: !user.get('_id'),
    panelIsOpen: !!documentViewer.uiState.get('panel'),
    forceSimulateSelection:
    documentViewer.uiState.get('panel') === 'targetReferencePanel' ||
    documentViewer.uiState.get('panel') === 'referencePanel'
  };
};

function mapDispatchToProps(dispatch) {
  const actions = {
    setSelection,
    unsetSelection,
    onClick: resetReferenceCreation,
    highlightReference,
    activateReference,
    scrollToActive,
    deactivateReference
  };
  return bindActionCreators(actions, dispatch);
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  return _objectSpread(_objectSpread(_objectSpread(_objectSpread({},
  stateProps),
  dispatchProps),
  ownProps), {}, {
    unsetSelection: dispatchProps.unsetSelection });

}
export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Document);