function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(obj, key, value) {key = _toPropertyKey(key);if (key in obj) {Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });} else {obj[key] = value;}return obj;}function _toPropertyKey(t) {var i = _toPrimitive(t, "string");return "symbol" == typeof i ? i : i + "";}function _toPrimitive(t, r) {if ("object" != typeof t || !t) return t;var e = t[Symbol.toPrimitive];if (void 0 !== e) {var i = e.call(t, r || "default");if ("object" != typeof i) return i;throw new TypeError("@@toPrimitive must return a primitive value.");}return ("string" === r ? String : Number)(t);}
import { httpRequest } from "../../../../../shared/superagent";


const sanitizeThesaurusValues = (
thesaurus,
values) =>
{
  const sanitizedThesaurus = _objectSpread(_objectSpread({}, thesaurus), {}, { values });
  sanitizedThesaurus.values =
  (values === null || values === void 0 ? void 0 : values.map((sValue) => {
    // @ts-ignore
    delete sValue.groupId;
    // @ts-ignore
    delete sValue._id;
    if (sValue.values) {
      sValue.values = sValue.values.map((ssValue) => {
        delete ssValue._id;
        // @ts-ignore
        delete ssValue.groupId;
        return ssValue;
      });
    }
    return sValue;
  })) || [];
  return sanitizedThesaurus;
};

function sanitizeThesauri(thesaurus) {
  const sanitizedThesauri = _objectSpread({}, thesaurus);
  sanitizedThesauri.values = sanitizedThesauri.
  values.filter((value) => value.label).
  filter((value) => !value.values || value.values.length).
  map((value) => {
    const _value = _objectSpread({}, value);
    if (_value.values) {
      _value.values = _value.values.filter((_v) => _v.label);
    }
    return _value;
  });
  return sanitizedThesauri;
}

const importThesaurus = async (
thesaurus,
file) =>
{
  const headers = {
    Accept: 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  };
  const fields = {
    thesauri: JSON.stringify(thesaurus)
  };

  return await httpRequest('thesauris', fields, headers, file);
};

export { sanitizeThesaurusValues, sanitizeThesauri, importThesaurus };