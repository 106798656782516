function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(obj, key, value) {key = _toPropertyKey(key);if (key in obj) {Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });} else {obj[key] = value;}return obj;}function _toPropertyKey(t) {var i = _toPrimitive(t, "string");return "symbol" == typeof i ? i : i + "";}function _toPrimitive(t, r) {if ("object" != typeof t || !t) return t;var e = t[Symbol.toPrimitive];if (void 0 !== e) {var i = e.call(t, r || "default");if ("object" != typeof i) return i;throw new TypeError("@@toPrimitive must return a primitive value.");}return ("string" === r ? String : Number)(t);}import { actions } from "../../BasicReducer/reducer";
import { actions as formActions } from 'react-redux-form';
import { RequestParams } from "../../utils/RequestParams";
import api from "../../utils/api";
import { notificationActions } from "../../Notifications";


import { ensure } from "../../../shared/tsUtils";


const tocGenerationActions = {
  reviewToc(fileId) {
    return async (dispatch, getState) => {
      const currentDoc = getState().documentViewer.doc.toJS();
      dispatch(formActions.reset('documentViewer.sidepanel.metadata'));

      const updatedFile = (await api.post('files/tocReviewed', new RequestParams({ fileId }))).json;
      const doc = _objectSpread(_objectSpread({},
      currentDoc), {}, {
        defaultDoc: updatedFile,
        documents: ensure(currentDoc.documents).map((d) => {
          if (d._id === updatedFile._id) {
            return updatedFile;
          }
          return d;
        }) });


      dispatch(notificationActions.notify('Document updated', 'success'));
      dispatch(formActions.reset('documentViewer.sidepanel.metadata'));
      dispatch(actions.set('viewer/doc', doc));
    };
  }
};

export { tocGenerationActions };