function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(obj, key, value) {key = _toPropertyKey(key);if (key in obj) {Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });} else {obj[key] = value;}return obj;}function _toPropertyKey(t) {var i = _toPrimitive(t, "string");return "symbol" == typeof i ? i : i + "";}function _toPrimitive(t, r) {if ("object" != typeof t || !t) return t;var e = t[Symbol.toPrimitive];if (void 0 !== e) {var i = e.call(t, r || "default");if ("object" != typeof i) return i;throw new TypeError("@@toPrimitive must return a primitive value.");}return ("string" === r ? String : Number)(t);}







const filterAvailableTemplates = (
templates,
filters) =>
{
  const usedTemplatesIds = [];

  filters === null || filters === void 0 ? void 0 : filters.forEach((filter) => {
    if (filter.items) {
      filter.items.forEach((item) => {
        usedTemplatesIds.push(item.id);
      });
    }

    if (filter.id) {
      usedTemplatesIds.push(filter.id);
    }
  });

  return templates.filter((template) => !usedTemplatesIds.includes(template._id));
};

const createNewFilters = (
selectedTemplatesIds,
templates) =>
{
  const newFilters = selectedTemplatesIds.map((templateId) => {
    const template = templates === null || templates === void 0 ? void 0 : templates.find((templ) => templ._id === templateId);
    return { id: templateId, name: template === null || template === void 0 ? void 0 : template.name };
  });

  return newFilters;
};

const updateFilters = (
newFilter,
filters) =>
{
  let isNewFilter = true;

  const updatedFilters = filters === null || filters === void 0 ? void 0 : filters.map((filter) => {
    if (filter.id === newFilter.id) {
      isNewFilter = false;
      return newFilter;
    }
    return filter;
  });

  if (isNewFilter) {
    return [...(updatedFilters || []), newFilter];
  }

  return updatedFilters;
};

const deleteFilters = (
originalFilters,
filtersToRemove) =>
{
  if (!filtersToRemove) {
    return originalFilters;
  }

  return originalFilters === null || originalFilters === void 0 ? void 0 : originalFilters.
  map((filter) => {
    if (filtersToRemove.includes(filter.id)) {
      return {};
    }

    if (filter.items) {
      const nestedFilters = filter.items.filter((item) => !filtersToRemove.includes(item.id));
      return _objectSpread(_objectSpread({}, filter), {}, { items: nestedFilters });
    }

    return _objectSpread({}, filter);
  }).
  filter((filter) => {
    if (!filter.id) {
      return false;
    }
    if (filter.items && filter.items.length === 0) {
      return false;
    }
    return true;
  });
};

const sanitizeFilters = (filters) => {
  const sanitizedFilters = filters === null || filters === void 0 ? void 0 : filters.map((filter) => {
    const sanitizedFilter = _objectSpread({}, filter);

    if (filter.items) {
      sanitizedFilter.items = filter.items.map(
        (item) => {
          const sanitizedItem = _objectSpread({}, item);
          if (sanitizedItem._id) {
            delete sanitizedItem._id;
          }
          return sanitizedItem;
        }
      );
    }

    return sanitizedFilter;
  });

  return sanitizedFilters;
};


export {
  filterAvailableTemplates,
  createNewFilters,
  updateFilters,
  deleteFilters,
  sanitizeFilters };