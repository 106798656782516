function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(obj, key, value) {key = _toPropertyKey(key);if (key in obj) {Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });} else {obj[key] = value;}return obj;}function _toPropertyKey(t) {var i = _toPrimitive(t, "string");return "symbol" == typeof i ? i : i + "";}function _toPrimitive(t, r) {if ("object" != typeof t || !t) return t;var e = t[Symbol.toPrimitive];if (void 0 !== e) {var i = e.call(t, r || "default");if ("object" != typeof i) return i;throw new TypeError("@@toPrimitive must return a primitive value.");}return ("string" === r ? String : Number)(t);}


// eslint-disable-next-line max-statements
const updateSuggestionsByEntity = (
currentSuggestions,
updatedEntity) =>
{var _updatedEntity$metada, _updatedEntity$metada2;
  if (!updatedEntity) {
    return currentSuggestions;
  }

  const suggestionToUpdate = currentSuggestions.find(
    (currentSuggestion) => currentSuggestion.entityId === updatedEntity._id
  );

  const propertyToUpdate = suggestionToUpdate === null || suggestionToUpdate === void 0 ? void 0 : suggestionToUpdate.propertyName;

  if (!suggestionToUpdate || !propertyToUpdate) {
    return currentSuggestions;
  }

  if (propertyToUpdate === 'title' && updatedEntity.title) {
    const newTitle = updatedEntity.title;
    suggestionToUpdate.currentValue = newTitle;
    suggestionToUpdate.entityTitle = newTitle;
    suggestionToUpdate.state.match = suggestionToUpdate.suggestedValue === newTitle;
  }

  if (
  propertyToUpdate !== 'title' &&
  updatedEntity.metadata && (_updatedEntity$metada =
  updatedEntity.metadata[propertyToUpdate]) !== null && _updatedEntity$metada !== void 0 && _updatedEntity$metada.length)
  {
    const newValue = updatedEntity.metadata[propertyToUpdate][0].value;
    suggestionToUpdate.currentValue = newValue;
    suggestionToUpdate.state.match = suggestionToUpdate.suggestedValue === newValue;
  }

  if (
  propertyToUpdate !== 'title' && (
  !updatedEntity.metadata || !((_updatedEntity$metada2 = updatedEntity.metadata[propertyToUpdate]) !== null && _updatedEntity$metada2 !== void 0 && _updatedEntity$metada2.length)))
  {
    suggestionToUpdate.currentValue = '';
    suggestionToUpdate.state.match = suggestionToUpdate.suggestedValue === '';
  }

  const suggestions = currentSuggestions.map((currentSuggestion) => {
    if (currentSuggestion._id === suggestionToUpdate._id) {
      return suggestionToUpdate;
    }
    return currentSuggestion;
  });

  return suggestions;
};

const updateSuggestions = (
currentSuggestions,
suggestionsToAccept) =>
{
  if (!suggestionsToAccept.length) {
    return currentSuggestions;
  }

  const acceptedSuggestions = suggestionsToAccept.map((suggestionToAccept) => {
    const updated = _objectSpread({}, suggestionToAccept);
    updated.state.match = true;
    updated.currentValue = suggestionToAccept.suggestedValue;

    if (
    suggestionToAccept.propertyName === 'title' &&
    typeof suggestionToAccept.suggestedValue === 'string')
    {
      updated.entityTitle = suggestionToAccept.suggestedValue;
    }

    return updated;
  });

  const merged = [
  ...currentSuggestions.
  concat(acceptedSuggestions).
  reduce(
    (map, suggestion) =>
    map.set(suggestion._id, Object.assign(map.get(suggestion._id) || {}, suggestion)),
    new Map()
  ).
  values()];


  return merged;
};

export { updateSuggestions, updateSuggestionsByEntity };