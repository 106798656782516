function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(obj, key, value) {key = _toPropertyKey(key);if (key in obj) {Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });} else {obj[key] = value;}return obj;}function _toPropertyKey(t) {var i = _toPrimitive(t, "string");return "symbol" == typeof i ? i : i + "";}function _toPrimitive(t, r) {if ("object" != typeof t || !t) return t;var e = t[Symbol.toPrimitive];if (void 0 !== e) {var i = e.call(t, r || "default");if ("object" != typeof i) return i;throw new TypeError("@@toPrimitive must return a primitive value.");}return ("string" === r ? String : Number)(t);} /** @format */

import moment from 'moment';

export default {
  performantDocToJSWithoutRelations(doc) {
    return doc.delete('relations').toJS();
  },

  prepareMetadata(doc, templates, thesauris) {
    const template = templates.find((t) => t._id === doc.template);

    if (!template || !thesauris.length) {
      return _objectSpread(_objectSpread({}, doc), {}, { metadata: [], documentType: '' });
    }

    const metadata = template.properties.map((property) => {
      let value = null;
      if (doc.metadata[property.name] && doc.metadata[property.name][0]) {
        [{ value }] = doc.metadata[property.name];
      }
      if (property.type === 'select' && value) {
        const thesauri = thesauris.
        find((t) => t._id === property.content).
        values.find((v) => v.id.toString() === value.toString());

        value = '';
        if (thesauri) {
          value = thesauri.label;
        }
      }

      if (property.type === 'date' && value) {
        value = moment(value, 'X').format('MMM DD, YYYY');
      }

      return { label: property.label, value };
    });

    return _objectSpread(_objectSpread({}, doc), {}, { metadata, documentType: template.name });
  }
};