function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(obj, key, value) {key = _toPropertyKey(key);if (key in obj) {Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });} else {obj[key] = value;}return obj;}function _toPropertyKey(t) {var i = _toPrimitive(t, "string");return "symbol" == typeof i ? i : i + "";}function _toPrimitive(t, r) {if ("object" != typeof t || !t) return t;var e = t[Symbol.toPrimitive];if (void 0 !== e) {var i = e.call(t, r || "default");if ("object" != typeof i) return i;throw new TypeError("@@toPrimitive must return a primitive value.");}return ("string" === r ? String : Number)(t);}import React from 'react';

import HtmlToReact, { Parser } from 'html-to-react';
import instanceMarkdownIt from 'markdown-it';
import mdContainer from 'markdown-it-container';
import * as CustomComponents from "./components";

const components = Object.keys(CustomComponents).reduce(
  (map, key) => _objectSpread(_objectSpread({}, map), {}, { [key.toLowerCase()]: CustomComponents[key] }),
  {}
);
const availableComponents = Object.keys(components);

const myParser = new Parser({ xmlMode: true });
const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);
const customComponentMatcher = '{\\w+}\\(.+\\)\\(.+\\)|{\\w+}\\(.+\\)';

const dynamicCustomContainersConfig = {
  validate() {
    return true;
  },
  render(tokens, idx) {
    const token = tokens[idx];

    if (token.type === 'container_dynamic_open') {
      return `<div class="${token.info.trim()}">`;
    }
    return '</div>';
  }
};

const markdownIt = instanceMarkdownIt({ xhtmlOut: true }).use(
  mdContainer,
  'dynamic',
  dynamicCustomContainersConfig
);
const markdownItWithHtml = instanceMarkdownIt({ html: true, xhtmlOut: true }).use(
  mdContainer,
  'dynamic',
  dynamicCustomContainersConfig
);
const customComponentTypeMatcher = /{(.+)}\(/;

const getConfig = (string) => {
  const customComponentOptionsMatcher = /{\w+}(\(.+\)\(.+\))|{\w+}(\(.+\))/g;
  let config;
  const configMatch = customComponentOptionsMatcher.exec(string);
  if (configMatch) {
    config = configMatch[1] || configMatch[2];
  }

  return config;
};

const removeWhitespacesInsideTableTags = (html) =>
html.
replace(
  /((\/)?(table|thead|tbody|tr|th|td)>)[\s\n]+(<(\/)?(table|thead|tbody|tr|th|td))/g,
  '$1$4'
).
replace(
  /((\/)?(table|thead|tbody|tr|th|td)>)[\s\n]+(<(\/)?(table|thead|tbody|tr|th|td))/g,
  '$1$4'
);

const getNodeTypeAndConfig = (_config, node, isCustomComponentPlaceholder, isCustomComponent) => {
  let type;
  let config = _config;

  if (isCustomComponentPlaceholder) {
    [, type] = node.children[0].data.match(customComponentTypeMatcher);
    config = getConfig(node.children[0].data);
  }

  if (isCustomComponent) {
    [, type] = node.data.match(customComponentTypeMatcher);
    config = getConfig(node.data);
  }

  type = availableComponents.includes(node.name ? node.name.toLowerCase() : '') ?
  components[node.name.toLowerCase()] :
  type;

  return { type, config };
};

export default ((_markdown, callback, withHtml = false) => {
  let renderer = markdownIt;
  if (withHtml) {
    renderer = markdownItWithHtml;
  }

  const markdown = _markdown.replace(new RegExp(`(${customComponentMatcher})`, 'g'), '$1\n');

  const html = removeWhitespacesInsideTableTags(
    renderer.
    render(markdown).
    replace(
      new RegExp(`<p>(${customComponentMatcher})</p>`, 'g'),
      '<placeholder>$1</placeholder>'
    )
  );

  const isValidNode = (node) => {
    const isBadNode = node.type === 'tag' && node.name.match(/<|>/g);
    if (isBadNode) {
      return false;
    }
    return true;
  };

  const processingInstructions = [
  {
    shouldProcessNode() {
      return true;
    },

    processNode: (node, children, index) => {
      if (
      node.name && (
      node.name.toLowerCase() === 'dataset' || node.name.toLowerCase() === 'query'))
      {
        return false;
      }
      const isCustomComponentPlaceholder =
      node.name === 'placeholder' &&
      node.children &&
      node.children[0] &&
      node.children[0].data &&
      node.children[0].data.match(customComponentMatcher);

      const isCustomComponent =
      node && (
      !node.parent || node.parent && node.parent.name !== 'placeholder') &&
      node.data &&
      node.data.match(customComponentMatcher);

      const { type, config } = getNodeTypeAndConfig(
        node.attribs,
        node,
        isCustomComponentPlaceholder,
        isCustomComponent
      );

      const newNode = callback(type, config, index, children);

      return newNode || processNodeDefinitions.processDefaultNode(node, children, index);
    }
  }];


  return myParser.parseWithInstructions(html, isValidNode, processingInstructions);
});