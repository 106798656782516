function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(obj, key, value) {key = _toPropertyKey(key);if (key in obj) {Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });} else {obj[key] = value;}return obj;}function _toPropertyKey(t) {var i = _toPrimitive(t, "string");return "symbol" == typeof i ? i : i + "";}function _toPrimitive(t, r) {if ("object" != typeof t || !t) return t;var e = t[Symbol.toPrimitive];if (void 0 !== e) {var i = e.call(t, r || "default");if ("object" != typeof i) return i;throw new TypeError("@@toPrimitive must return a primitive value.");}return ("string" === r ? String : Number)(t);}

import { I18NApi } from "../../../I18N";

import { RequestParams } from "../../../utils/RequestParams";
import { httpRequest } from "../../../../shared/superagent";
import loadingBar from "../../../App/LoadingProgressBar";

const filterTranslationsByContext = (
translations,
contextId) =>

translations.map((language) => {
  const contexts = language.contexts.filter((context) => context.id === contextId);
  return _objectSpread(_objectSpread({}, language), {}, { contexts });
});

const get = async (
headers,
params) =>
{
  const requestParams = new RequestParams(_objectSpread({}, params), headers);
  const response = I18NApi.get(requestParams);
  return response;
};

const post = async (updatedTranslations, contextId) => {
  try {
    const translations = await Promise.all(
      updatedTranslations.map((language) => I18NApi.save(new RequestParams(language)))
    );
    return filterTranslationsByContext(translations, contextId);
  } catch (e) {
    return e;
  }
};

const importTranslations = async (
file,
contextId) =>
{
  loadingBar.start();
  try {
    const translations = await httpRequest(
      'translations/import',
      { context: contextId },
      {
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      },
      file
    );
    return filterTranslationsByContext(translations, contextId);
  } catch (e) {
    return e;
  } finally {
    loadingBar.done();
  }
};

const { getLanguages } = I18NApi;

export { get, post, importTranslations, getLanguages };