function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(obj, key, value) {key = _toPropertyKey(key);if (key in obj) {Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });} else {obj[key] = value;}return obj;}function _toPropertyKey(t) {var i = _toPrimitive(t, "string");return "symbol" == typeof i ? i : i + "";}function _toPrimitive(t, r) {if ("object" != typeof t || !t) return t;var e = t[Symbol.toPrimitive];if (void 0 !== e) {var i = e.call(t, r || "default");if ("object" != typeof i) return i;throw new TypeError("@@toPrimitive must return a primitive value.");}return ("string" === r ? String : Number)(t);}
import UsersAPI from "../../../Users/UsersAPI";
import api from "../../../utils/api";
import { RequestParams } from "../../../utils/RequestParams";


const prepareUser = (user) => {
  const preparedUser = _objectSpread({}, user);

  if (!preparedUser.password) {
    delete preparedUser.password;
  }
  delete preparedUser.accountLocked;
  delete preparedUser.failedLogins;

  return preparedUser;
};

const newUser = async (user, headers) => {
  try {
    const createdUser = prepareUser(user);
    const requestParams = new RequestParams(createdUser, headers);
    const response = await UsersAPI.new(requestParams);
    return response;
  } catch (e) {
    return e;
  }
};

const updateUser = async (user, headers) => {
  try {
    const updatedUser = prepareUser(user);
    const requestParams = new RequestParams(updatedUser, headers);
    const response = await UsersAPI.save(requestParams);
    return response;
  } catch (e) {
    return e;
  }
};

const deleteUser = async (users, headers) => {
  try {
    const requestParams = new RequestParams({ ids: users.map((user) => user._id) }, headers);
    const response = await UsersAPI.delete(requestParams);
    return response;
  } catch (e) {
    return e;
  }
};

const saveGroup = async (group, headers) => {
  try {
    const requestParams = new RequestParams(group, headers);
    const response = await api.post('usergroups', requestParams);
    return response.json;
  } catch (e) {
    return e;
  }
};

const deleteGroup = async (groups, headers) => {
  try {
    const requestParams = new RequestParams(
      { ids: groups.map((group) => group._id) },
      headers
    );
    const response = await api.delete('usergroups', requestParams);
    return response.json;
  } catch (e) {
    return e;
  }
};

const unlockAccount = async (user, headers) => {
  try {
    const requestParams = new RequestParams({ _id: user._id }, headers);
    const response = await UsersAPI.unlockAccount(requestParams);
    return response;
  } catch (e) {
    return e;
  }
};

const resetPassword = async (
data,
headers) =>
{
  try {
    if (Array.isArray(data)) {
      const response = await Promise.all(
        data.map((user) => {
          const requestParams = new RequestParams({ email: user.email }, headers);
          return api.post('recoverpassword', requestParams);
        })
      );
      return response;
    }

    const requestParams = new RequestParams({ email: data.email }, headers);
    const response = await api.post('recoverpassword', requestParams);
    return response;
  } catch (e) {
    return e;
  }
};

const reset2FA = async (
data,
headers) =>
{
  try {
    if (Array.isArray(data)) {
      const response = await Promise.all(
        data.map((user) => {
          const requestParams = new RequestParams({ _id: user._id }, headers);
          return api.post('auth2fa-reset', requestParams);
        })
      );
      return response;
    }

    const requestParams = new RequestParams({ _id: data._id }, headers);
    const response = await api.post('auth2fa-reset', requestParams);
    return response;
  } catch (e) {
    return e;
  }
};

const get = async (headers) => {
  try {
    const requestParams = new RequestParams({}, headers);
    const response = await UsersAPI.get(requestParams);
    return response;
  } catch (e) {
    return e;
  }
};

const getUserGroups = async (headers) => {
  try {
    const requestParams = new RequestParams({}, headers);
    const response = await api.get('usergroups', requestParams);
    return response.json;
  } catch (e) {
    return e;
  }
};

export {
  get,
  getUserGroups,
  newUser,
  updateUser,
  deleteUser,
  saveGroup,
  deleteGroup,
  unlockAccount,
  resetPassword,
  reset2FA };