function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(obj, key, value) {key = _toPropertyKey(key);if (key in obj) {Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });} else {obj[key] = value;}return obj;}function _toPropertyKey(t) {var i = _toPrimitive(t, "string");return "symbol" == typeof i ? i : i + "";}function _toPrimitive(t, r) {if ("object" != typeof t || !t) return t;var e = t[Symbol.toPrimitive];if (void 0 !== e) {var i = e.call(t, r || "default");if ("object" != typeof i) return i;throw new TypeError("@@toPrimitive must return a primitive value.");}return ("string" === r ? String : Number)(t);}import { risonDecodeOrIgnore } from "../../utils";
import { get, has, uniq } from 'lodash';
import api from "../../Search/SearchAPI";
import { markdownDatasets } from "../../Markdown";

import PagesAPI from "../PagesAPI";
import pageItemLists from "./pageItemLists";










const buildQuery = (sanitizedParams) => {
  const queryDefault = { filters: {}, types: [] };
  if (sanitizedParams) {
    const query = risonDecodeOrIgnore(sanitizedParams.replace('?q=', '') || '()');
    if (typeof query !== 'object') {
      return queryDefault;
    }
    return query;
  }
  return queryDefault;
};

const prepareLists = (content, requestParams) => {
  const listsData = pageItemLists.generate(content);

  listsData.searchs = Promise.all(
    listsData.params.map((params, index) => {
      const sanitizedParams = params ? decodeURI(params) : '';

      const query = buildQuery(sanitizedParams);

      query.limit = listsData.options[index].limit ? String(listsData.options[index].limit) : '6';
      return api.search(requestParams.set(query));
    })
  );

  return listsData;
};

const replaceDynamicProperties = (pageContent, datasets) => {
  if (!pageContent || !datasets || !datasets.entityData && !datasets.template) {
    return { content: pageContent, errors: [] };
  }

  const parsableDatasets = {
    entity: datasets.entityData,
    template: datasets.template
  };

  const errors = [];
  const content = pageContent.replace(/\$\{((entity.|template.)[^}^\s]*)\}/g, (match, p) => {
    switch (true) {
      case /entity.metadata.\w*$/.test(p):
        return get(parsableDatasets, `${p}[0].value`);

      case /entity.metadata.\w*.(value|displayValue)$/.test(p):{
          const path = p.split('.');
          const pathEnd = path.pop();
          return get(parsableDatasets, `${path.join('.')}[0].${pathEnd}`);
        }

      case /entity.metadata.\w*\[\d+]$/.test(p):
        return get(parsableDatasets, `${p}.value`);

      case has(parsableDatasets, p):
        return get(parsableDatasets, p);

      default:
        errors.push(match);
        return match;
    }
  });
  return { content, errors };
};

const getPageAssets = async (
requestParams,
additionalDatasets,
localDatasets) =>
{var _page$metadata;
  const page = await PagesAPI.getById(requestParams);

  const { content, errors } = replaceDynamicProperties((_page$metadata = page.metadata) === null || _page$metadata === void 0 ? void 0 : _page$metadata.content, localDatasets);
  page.metadata.content = content;

  const listsData = prepareLists(page.metadata.content, requestParams);

  const dataSets = markdownDatasets.fetch(page.metadata.content, requestParams.onlyHeaders(), {
    additionalDatasets
  });

  const [pageView, searchParams, searchOptions, datasets, listSearchs] = await Promise.all(

    [page, listsData.params, listsData.options, dataSets, listsData.searchs]);
  pageView.scriptRendered = false;

  const itemLists = searchParams.map((p, index) => ({
    params: p,
    items: listSearchs[index].rows,
    options: searchOptions[index]
  }));

  const failedExpressions = uniq(errors).join('\n');
  return _objectSpread({
    pageView,
    itemLists,
    datasets: _objectSpread(_objectSpread({}, datasets), localDatasets) },
  failedExpressions.length && {
    errors: `The following expressions are not valid properties:\n ${failedExpressions}`
  });

};

export { getPageAssets };