function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(obj, key, value) {key = _toPropertyKey(key);if (key in obj) {Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });} else {obj[key] = value;}return obj;}function _toPropertyKey(t) {var i = _toPrimitive(t, "string");return "symbol" == typeof i ? i : i + "";}function _toPrimitive(t, r) {if ("object" != typeof t || !t) return t;var e = t[Symbol.toPrimitive];if (void 0 !== e) {var i = e.call(t, r || "default");if ("object" != typeof i) return i;throw new TypeError("@@toPrimitive must return a primitive value.");}return ("string" === r ? String : Number)(t);}

import { ensure } from "../../../shared/tsUtils";





function columnsFromTemplates(templates) {
  return templates.reduce((properties, template) => {
    const propsToAdd = [];
    (template.properties || []).forEach((property) => {
      if (
      !['image', 'preview', 'media', 'nested'].includes(property.type) &&
      !properties.find((columnProperty) => property.name === columnProperty.name))
      {
        propsToAdd.push(_objectSpread(_objectSpread({}, property), {}, { translationContext: ensure(template._id) }));
      }
    });
    return properties.concat(propsToAdd);
  }, []);
}

const getTemplatesToProcess = (
documents,
templates,
useTemplates) =>
{
  const queriedTemplates = documents.aggregations.all._types.buckets;
  if (useTemplates.length || queriedTemplates) {
    const templateIds = useTemplates.length ?
    useTemplates :
    queriedTemplates.
    filter((template) => template.filtered.doc_count > 0).
    map((template) => template.key);

    return templates.filter((t) =>
    templateIds.find((id) => t._id === id)
    );
  }
  return [];
};

export function getTableColumns(
documents,
templates,
useTemplates = [])
{
  let columns = [];
  const templatesToProcess = getTemplatesToProcess(
    documents,
    templates,
    useTemplates
  );
  if (templatesToProcess.length > 0) {
    const commonColumns = [
    ...(templatesToProcess[0].commonProperties || []),
    {
      label: 'Template',
      name: 'templateName',
      type: 'text',
      isCommonProperty: true
    }];


    columns = commonColumns.
    map((c) => _objectSpread(_objectSpread({}, c), {}, { showInCard: true })).
    concat(columnsFromTemplates(templatesToProcess));
  }
  return columns;
}